import Home from '@/pages/Home';
import Login from '@/pages/Login';

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/helpers/store'

import VueI18n from 'vue-i18n'
import dictionary from '@/plugins/i18n'
const i18n = new VueI18n(dictionary)

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {}
    },
    {
        path: '/privacy',
        component: () => import('@/pages/Privacy'),
        meta: {}
    },
    {
        path: '/result/:id',
        component: () => import('@/pages/Result'),
        meta: {}
    },
    {
        path: "/login",
        component: Login,
        meta: {}
    },
    {
        path: '*', component: Home
    },
    {
        path: '/myInformation',
        component: () => import('@/pages/MyInformation'),
        meta: {
            role: 'USER',
        }
    },
    {
        path: '/doctorregister',
        component: () => import('@/pages/DoctorRegister'),
        meta: {}
    },
    {
        path: '/recovery/:token',
        component: () => import('@/pages/Recovery'),
        meta: {}
    },
    {
        path: "/recovery",
        component: () => import('@/pages/Recovery'),
        meta: {}
    },
    {
        path: '/confirmregister/:token',
        component: () => import('@/pages/ConfirmRegister'),
        meta: {}
    },
    {
        path: '/pendingDoctor',
        component: () => import('@/pages/PendingDoctor'),
        meta: {
            role: 'PRE_DOCTOR',
            menu: {
                title: i18n.t('Cadastro'),
                icon: 'contact_mail',
            },
        }
    },
    {
        path: '/service',
        component: () => import('@/pages/Service'),
        meta: {
            role: 'ATTENDANT',
            menu: {
                title: i18n.t('Atendimento ao Cliente'),
                icon: 'shopping_cart',
            },
        }
    },
    {
        path: '/results',
        component: () => import('@/pages/Results/List'),
        meta: {
            role: 'ATTENDANT',
            menu: {
                title: i18n.t('Resultados de exames'),
                icon: 'new_releases',
            },
        }
    },
    {
        path: '/results/:id',
        component: () => import('@/pages/Results/Release'),
        meta: {
            role: 'ATTENDANT',
        }
    },
    {
        path: '/payments',
        component: () => import('@/pages/Payment/List'),
        meta: {
            role: 'ATTENDANT',
            menu: {
                title: i18n.t('Tela de pagamento'),
                icon: 'attach_money',
            },
        }
    },
    {
        path: '/payments/:id',
        component: () => import('@/pages/Payment/Release'),
        meta: {
            role: 'ATTENDANT',
        }
    },
    {
        path: '/clients',
        component: () => import('@/pages/Clients'),
        meta: {
            role: 'ATTENDANT',
            menu: {
                title: i18n.t('Clientes'),
                icon: 'groups',
            },
        }
    },
    {
        path: '/doctors',
        component: () => import('@/pages/Doctor'),
        meta: {
            role: 'ATTENDANT',
            menu: {
                title: i18n.t('Médico'),
                icon: 'mdi-doctor',
            },
        }
    },
    {
        path: '/daily-report',
        component: () => import('@/pages/DailyReport'),
        meta: {
            role: 'FINANCIAL',
            menu: {
                title: i18n.t('Relatórios do dia'),
                icon: 'receipt_long',
            },
        }
    },
    {
        path: '/pendency',
        component: () => import('@/pages/Pendency'),
        meta: {
            role: 'FINANCIAL',
            menu: {
                title: i18n.t('Pendências'),
                icon: 'block',
            },
        }
    },
    {
        path: '/financial',
        component: () => import('@/pages/Financial'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Relatórios financeiros'),
                icon: 'attach_money',
            },
        }
    },
    {
        path: '/expense',
        component: () => import('@/pages/Expense'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Despesas'),
                icon: 'money_off',
            },
        }
    },
    {
        path: '/services',
        component: () => import('@/pages/Services'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Atendimentos'),
                icon: 'shopping_cart',
            },
        }
    },
    {
        path: '/partner',
        component: () => import('@/pages/Partner'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Convênios'),
                icon: 'group_work',
            },
        }
    },
    {
        path: '/exam',
        component: () => import('@/pages/Exam'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Exames'),
                icon: 'assignment',
            },
        }
    },
    {
        path: '/stores',
        component: () => import('@/pages/Store'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Lojas'),
                icon: 'store',
            },
        }
    },
    {
        path: '/users',
        component: () => import('@/pages/User'),
        meta: {
            role: 'ADMIN',
            menu: {
                title: i18n.t('Gerenciamento de Usuários'),
                icon: 'people',
            },
        }
    },
];

const router = new VueRouter({
    routes,
    mode: 'history'
});

router.beforeEach((routeTo, routeFrom, next) => {
    store.commit("FIRST_REQUEST", true);

    if (routeTo.path == "/login") {
        store.state.routeBeforeLogin = routeFrom.path;
    }

    // Enter here if route has a role to access
    if (routeTo.meta && routeTo.meta.role) {
        try {
            // If user not logged or not contains permission, send to home page
            if (store.getters.verifyRole(routeTo.meta.role) == false) {
                return next({ path: routeTo.meta.redirectToWhenNotPermit ? routeTo.meta.redirectToWhenNotPermit : "/" });
            }
        } catch (e) {
            return next({ path: "/" });
        }
    }
    next()
})

export default router