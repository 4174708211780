<template>
    <div class="text-xs-center">
        <v-bottom-sheet v-model="showInfo">
            <v-footer padless>
                <v-card class="flex" flat tile>
                    <v-card-title class="primary">
                        <strong class="subheading">Powered by Sdeveloper</strong>

                        <v-spacer></v-spacer>

                        <v-btn-toggle v-model="$i18n.locale" mandatory background-color="primary">
                            <v-btn value="pt" color="primary" @click="changeLang('pt')">
                                <v-avatar size="30px">
                                    <img :src="require('@/assets/images/pt.png')" alt="Português" />
                                </v-avatar>
                            </v-btn>
                            <v-btn value="en" color="primary" @click="changeLang('en')">
                                <v-avatar size="30px">
                                    <img :src="require('@/assets/images/en.png')" alt="English" />
                                </v-avatar>
                            </v-btn>
                        </v-btn-toggle>
                    </v-card-title>
                    <v-card-text class="primary">
                        <div><v-icon size="20px" left>email</v-icon>anderson@sdeveloper.io</div>
                        <router-link to="/privacy" tag="v-btn">
                            <center><v-btn text x-small>Política de Privacidade</v-btn></center>
                        </router-link>
                    </v-card-text>
                    <v-card-text>
                        <v-layout wrap>
                            <v-flex xs4 text-left>
                                <Version />
                            </v-flex>
                            <v-flex xs4 text-center>
                                <v-btn outlined small href="https://sdeveloper.io" target="_blank">
                                    &copy;{{ new Date().getFullYear() }} —
                                    <strong>sdeveloper.io</strong>
                                </v-btn>
                            </v-flex>
                            <v-flex xs4 text-right>
                                <v-btn fab x-small :color="$vuetify.theme.dark ? 'white' : 'black'" @click="changeDarkMode">
                                    <v-icon :color="$vuetify.theme.dark ? 'black' : 'white'">mdi-theme-light-dark</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-card-text>
                </v-card>
            </v-footer>
        </v-bottom-sheet>
    </div>
</template>

<script>
import Version from "@/components/core/Version";

export default {
    name: "Info",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    components: { Version },

    data() {
        return { langs: ["pt", "en"] };
    },

    methods: {
        changeLang(lang) {
            this.$i18n.locale = lang;
            this.$store.commit("SET_LANG", lang);
        },
        changeDarkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$store.commit("SET_DARK_MODE", this.$vuetify.theme.dark);
        },
    },

    computed: {
        showInfo: {
            get: function () {
                return this.show;
            },
            set: function (value) {
                if (!value) {
                    this.$emit("onClose");
                }
            },
        },
    },
};
</script>