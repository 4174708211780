<template>
    <div>
        <div id="main_page" v-if="!$store.getters.isLogged" style="height: 100vh; width: 100vw; background-color: var(--v-secondary-base)">
            <!-- <v-card style="height: 50vh; width: 50vw" class="mx-auto rounded-xl white--text" color="white">
                <v-card-title primary-title class="justify-center align-self-center">
                    <img class="mx-auto" :src="require('@/assets/images/labvida.png')" />
                </v-card-title>
            </v-card> -->
            <v-container id="box" style="background-color: white" class="mx-auto rounded-xl white--text" fill-height fluid>
                <v-row align="center" justify="center">
                    <v-col align="center" justify="center">
                        <img class="mx-auto" id="image" :aspect-ratio="16 / 9" :src="require('@/assets/images/labvida.png')" />
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col align="center" justify="center">
                        <v-btn @click="showQRCode = true" color="secondary" style="width: 100%" class="elevation-24" dark :small="$vuetify.breakpoint.xs"
                            :x-large="$vuetify.breakpoint.smAndUp">
                            <v-icon left dark :small="$vuetify.breakpoint.xs" :large="$vuetify.breakpoint.smAndUp" class="mr-sm-12"> description </v-icon>
                            Resultado
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col cols="12" md="6" lg="4">
                        <v-btn class="green" style="width: 100%" dark :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xs" href="https://wa.me/5535998862425"
                            target="_blank">
                            <v-icon size="24px" left :small="$vuetify.breakpoint.xs">fab fa-whatsapp</v-icon>
                            Unidade Brasópolis
                        </v-btn>
                    </v-col>
                    <v-col cols="12" md="6" lg="4">
                        <v-btn class="green" style="width: 100%" dark :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xs" href="https://wa.me/5535998862425"
                            target="_blank">
                            <v-icon size="24px" left :small="$vuetify.breakpoint.xs">fab fa-whatsapp</v-icon>
                            Unidade Piranguinho
                        </v-btn>
                    </v-col>
                    <v-col cols="12" lg="4">
                        <v-btn class="instagram" style="width: 100%" dark :small="$vuetify.breakpoint.smAndUp" :x-small="$vuetify.breakpoint.xs"
                            href="https://www.instagram.com/labvida012010/" target="_blank">
                            <v-icon size="24px" left :small="$vuetify.breakpoint.xs">fab fa-instagram</v-icon>
                            Instagram
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <QRCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
        </div>
        <div id="main" v-else>
            <div id="banner">
                <v-container class="mx-auto rounded-xl white--text" fill-height fluid>
                    <v-row align="center" justify="center">
                        <v-col align="center" justify="center">
                            <img class="mx-auto" style="max-height: 120px" :src="require('@/assets/images/labvida.png')" />
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <div id="content">
                <v-container grid-list-xl>
                    <v-layout wrap>
                        <v-flex xs12 class="ma-4 ma-sm-12">
                            <router-link :to="{ path: '/service' }" tag="v-btn">
                                <v-btn style="width: 100%" class="elevation-24" :x-large="$vuetify.breakpoint.smAndUp">
                                    <v-icon left dark :large="$vuetify.breakpoint.smAndUp" class="mr-12"> shopping_cart </v-icon>
                                    Atendimento ao Cliente
                                </v-btn>
                            </router-link>
                        </v-flex>
                        <v-flex xs12 class="ma-4 ma-sm-12">
                            <router-link :to="{ path: '/results' }" tag="v-btn">
                                <v-btn style="width: 100%" class="elevation-24" :x-large="$vuetify.breakpoint.smAndUp">
                                    <v-icon left dark :large="$vuetify.breakpoint.smAndUp" class="mr-12"> new_releases </v-icon>
                                    Resultados de exame
                                </v-btn>
                            </router-link>
                        </v-flex>
                        <v-flex xs12 class="ma-4 ma-sm-12">
                            <router-link :to="{ path: '/payments' }" tag="v-btn">
                                <v-btn style="width: 100%" class="elevation-24" :x-large="$vuetify.breakpoint.smAndUp">
                                    <v-icon left dark :large="$vuetify.breakpoint.smAndUp" class="mr-12"> attach_money </v-icon>
                                    Pagamentos
                                </v-btn>
                            </router-link>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
    </div>
</template>

<script>
import QRCode from "@/components/core/QRCode";

export default {
    mounted() {
        this.update();
        this.timer = setInterval(this.update, 10000);
    },
    components: { QRCode },
    data() {
        return {
            loading: true,
            timer: null,
            showQRCode: false,
        };
    },
    methods: {
        update() { },
        onConnectionFailed() { },
        readedQRCode(QRCode) {
            if (QRCode.startsWith("https://labvida.app")) {
                this.showQRCode = false;
                window.location.href = QRCode;
            } else {
                this.$eventHub.$emit("msgError", this.$t("Código inválido."));
            }
        },
    },
    computed: {},
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>

<style scoped>
#content {
    width: 100vw;
    height: 84vh;
    background-color: var(--v-secondary-base);
}

#banner {
    width: 100vw;
    height: 16vh;
    background-color: rgb(255, 255, 255);
    /* background-color: var(--v-primary-base); */
}

#banner img {
    width: auto;
    height: 22vh;
}

.instagram {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

@media screen and (min-width: 1280px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 75vw;
    }

    #box {
        height: 60vh;
        width: 50vw;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 75vw;
    }

    #box {
        height: 60vh;
        width: 60vw;
    }
}

@media screen and (min-width: 540px) and (max-width: 1023px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 75vw;
    }

    #box {
        height: 60vh;
        width: 80vw;
    }
}

@media screen and (max-width: 540px) {
    #main_page {
        padding-top: 20vh;
    }

    #image {
        max-width: 75vw;
    }

    #box {
        height: 60vh;
        width: 85vw;
    }
}
</style>