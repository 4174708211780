<template>
    <v-container fluid fill-height class="loginOverlay">
        <v-layout flex align-center justify-center>
            <v-flex xs12 sm8 md6 xl4 elevation-6>
                <v-toolbar class="primary">
                    <v-toolbar-title class="white--text">
                        <h4>Labvida</h4>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card>
                    <v-card-text class="pt-4">
                        <LoginComponent @login="$router.replace({ path: $store.state.routeBeforeLogin })"></LoginComponent>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import LoginComponent from "@/components/form/Login";
export default {
    name: "Login",
    components: {
        LoginComponent,
    },
};
</script>