<template>
    <div class="text-center">
        <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
            <template v-slot:activator="{ on, attrs }">
                <v-btn absolute fab color="primary" class="elevation-24" style="top: 4px; right: 10px" v-bind="attrs" v-on="on">
                    <v-icon>menu</v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar> </v-list-item-avatar>
                        <v-list-item-content> </v-list-item-content>

                        <v-list-item-action>
                            <v-btn class="gray--text" icon @click="menu = false">
                                <v-icon>close</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>

                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title class="title">{{ $t("Olá") }}, {{ this.$store.state.user.name }}!!!</v-list-item-title>
                            <v-list-item-subtitle>{{ this.$store.state.login.email }}</v-list-item-subtitle>
                            <Version class="mt-12" />
                        </v-list-item-content>

                        <v-list-item-action>
                            <router-link to="/" tag="v-btn">
                                <v-btn class="primary--text elevation-12 my-1" icon @click="menu = false">
                                    <v-icon>home</v-icon>
                                </v-btn>
                            </router-link>
                            <router-link to="/myinformation" tag="v-btn">
                                <v-btn class="primary--text elevation-12 my-1" icon @click="menu = false">
                                    <v-icon>settings</v-icon>
                                </v-btn>
                            </router-link>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>

                <div v-if="verifyRole('USER')">
                    <v-divider></v-divider>
                    <v-list dense v-for="(item, i) in getMenus('USER')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="verifyRole('PRE_DOCTOR')">
                    <v-divider></v-divider>
                    <v-list dense v-for="(item, i) in getMenus('PRE_DOCTOR')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="verifyRole('DOCTOR')">
                    <v-divider></v-divider>
                    <v-list dense v-for="(item, i) in getMenus('DOCTOR')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="verifyRole('ATTENDANT')">
                    <v-divider></v-divider>
                    <v-subheader>{{ $t("Atendente") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('ATTENDANT')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>
                <!-- 
                <div v-if="verifyRole('RESPONSIBLE')">
                    <v-divider></v-divider>
                    <v-subheader>{{ $t("Responsável Técnico") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('RESPONSIBLE')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div> -->

                <div v-if="verifyRole('FINANCIAL')">
                    <v-divider></v-divider>
                    <v-subheader>{{ $t("Financeiro") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('FINANCIAL')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                </div>

                <div v-if="verifyRole('ADMIN')">
                    <v-divider></v-divider>
                    <v-subheader>{{ $t("Administrador") }}</v-subheader>
                    <v-list dense v-for="(item, i) in getMenus('ADMIN')" :key="i">
                        <router-link :to="{ path: item.path }" tag="v-list-item">
                            <v-list-item @click="menu = false">
                                <v-list-item-icon v-if="item.meta.menu.icon">
                                    <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                            </v-list-item>
                        </router-link>
                    </v-list>
                    <!-- <v-list dense>
                        <v-list-group :value="false" no-action sub-group>
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t("Outros") }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <v-list dense v-for="(item, i) in getSubmenus('ROLE_ADMIN')" :key="i">
                                <router-link :to="{ path: item.path }" tag="v-list-item">
                                    <v-list-item @click="menu = false">
                                        <v-list-item-icon v-if="item.meta.menu.icon">
                                            <v-icon color="primary">{{ item.meta.menu.icon }}</v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-title v-if="item.meta.menu.title">{{ item.meta.menu.title }}</v-list-item-title>
                                    </v-list-item>
                                </router-link>
                            </v-list>
                        </v-list-group>
                    </v-list> -->
                </div>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <router-link :to="{ path: '/' }" v-on:click.native="$store.commit('CLEAR_LOGIN') & $emit('logout')">
                        <v-btn dark color="primary" @click="menu = false"> <v-icon left dark>exit_to_app</v-icon>Sair</v-btn>
                    </router-link>
                </v-card-actions>
            </v-card>
        </v-menu>
    </div>
</template>

<script>
import Version from "@/components/core/Version";
import { mapGetters } from "vuex";

export default {
    name: "Menu",

    data() {
        return {
            menu: false,
        };
    },

    components: { Version },

    computed: {
        ...mapGetters(["verifyRole"]),
        getMenus() {
            return (role) =>
                this.$router.options.routes.filter(
                    (route) => route.meta && route.meta.role == role && route.meta.menu && !route.meta.menu.level && (route.meta.menu.icon || route.meta.menu.title)
                );
        },
        getSubmenus() {
            return (role) =>
                this.$router.options.routes.filter(
                    (route) => route.meta && route.meta.role == role && route.meta.menu && route.meta.menu.level == 2 && (route.meta.menu.icon || route.meta.menu.title)
                );
        },
    },
};
</script>