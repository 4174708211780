import axios from 'axios'
import store from '@/helpers/store'
import router from '@/helpers/router'
import eventHub from '@/eventHub'

const http = axios.create({
    baseURL: (process.env.NODE_ENV === 'production') ? 'https://sdeveloper.io:3000/' : 'http://localhost:3000/',
});

http.interceptors.request.use(req => {
    if (store.getters.isLogged) {
        req.headers.Authorization = `Bearer ${store.state.token}`;
    }
    return req;
}, err => {
    return Promise.reject(err);
});

http.interceptors.response.use(response => {
    store.commit("ONLINE");
    return response.data;
}, async function (error) {
    if (error && error.config) {
        const originalRequest = error.config;

        const status = error.response ? error.response.status : null;
        if (status === 401) {
            if (router.currentRoute.path != "/login") {
                if (error.response && error.response.config && error.response.config.url != "/auth" && store.state.login.remember) {
                    let req = { email: store.state.login.email, password: store.state.login.password, notification: store.state.notification };
                    let loginOk = false;
                    return http.post("/auth", req).then(
                        response => {
                            store.commit("SET_LOGIN", { response, email: store.state.login.email, password: store.state.login.password, remember: true });
                            loginOk = true;
                            return http.request(originalRequest);
                        }
                    ).catch(() => {
                        if (loginOk == false) {
                            store.commit("CLEAR_LOGIN");
                            if (router.currentRoute.path != "/") {
                                router.replace({ path: "/" });
                            }
                        }
                    });
                } else {
                    store.commit("CLEAR_LOGIN");
                    if (router.currentRoute.path != "/") {
                        router.replace({ path: "/" });
                    }
                }
            }
        } else if (status === 403) {
            eventHub.$emit("msgError", "Sem permissão para acessar essa tela.");
            if (router.currentRoute.path != "/") {
                router.replace({ path: "/" });
            }
        }
    }

    if (error.response) {
        store.commit("ONLINE");
    } else {
        store.commit("OFFLINE");
    }

    return error.response ? Promise.reject(error.response.data) : Promise.reject({});
});

export default http