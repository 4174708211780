<template>
    <v-app id="create">
        <v-banner v-if="deferredPrompt" color="accent" dark class="text-left elevation-24">
            {{ $t("Para instalar o aplicativo no seu dispositivo basta clicar no botão 'Instalar'.") }}
            <template v-slot:actions>
                <v-btn color="green" @click="install">{{ $t("Instalar") }}</v-btn>
                <v-btn text @click="dismiss">{{ $t("Cancelar") }}</v-btn>
            </template>
        </v-banner>

        <Menu v-if="this.$router.currentRoute.path != '/login' && this.$router.currentRoute.path != '/register'" />
        <Message ref="msg"></Message>

        <v-dialog v-model="$store.getters.isOffline" persistent max-width="290">
            <v-card color="primary">
                <v-card-text class="text-center pt-8">
                    <v-icon x-large color="white">cloud_off</v-icon>
                </v-card-text>
                <v-card-text class="headline white--text">{{ $t("Você está OFFLINE") }} :(</v-card-text>
                <v-card-text>{{ $t("Reconecte na internet para continuar") }}. ({{ $store.state.offlineCounter }}) </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showUpdate" max-width="350">
            <v-card color="success">
                <v-card-text class="text-center pt-8">
                    <v-icon x-large color="white">update</v-icon>
                </v-card-text>
                <v-card-text class="headline white--text">{{ $t("Atualizamos o sistema.") }}</v-card-text>
                <v-card-text>{{ $t("Estamos sempre buscando novas formas de melhorar sua experiência.") }}</v-card-text>
            </v-card>
        </v-dialog>

        <transition name="body">
            <router-view></router-view>
        </transition>

        <v-flex xs12 sm12 md12 style="height: 0px; position: relative"> </v-flex>

        <v-speed-dial :bottom="true" :right="true" direction="top" transition="slide-y-reverse-transition">
            <template v-slot:activator>
                <v-btn color="primary" fab>
                    <v-icon> mdi-unfold-more-horizontal </v-icon>
                </v-btn>
            </template>
            <router-link to="/home" tag="v-btn">
                <v-btn fab dark small color="primary">
                    <v-icon>home</v-icon>
                </v-btn>
            </router-link>
            <v-btn fab small color="success" @click="info.show = !info.show">
                <v-icon> info </v-icon>
            </v-btn>
            <router-link to="/privacy" tag="v-btn">
                <v-btn fab dark small color="red">
                    <v-icon>security</v-icon>
                </v-btn>
            </router-link>
            <v-btn fab small :color="$vuetify.theme.dark ? 'white' : 'black'" @click="changeDarkMode">
                <v-icon :color="$vuetify.theme.dark ? 'black' : 'white'">mdi-theme-light-dark</v-icon>
            </v-btn>
        </v-speed-dial>

        <Info :show="info.show" @onClose="closeInfo()"></Info>
        <Loading v-if="$store.getters.isLoading"></Loading>
    </v-app>
</template>

<script>
import Message from "@/components/core/Message";
import Loading from "@/components/core/Loading";
import Info from "@/components/shared/Info";
import Menu from "@/components/shared/Menu";

export default {
    name: "App",
    components: {
        Menu,
        Info,
        Message,
        Loading,
    },
    data() {
        return {
            deferredPrompt: null,
            showUpdate: false,
            timer: null,
            info: {
                show: false,
            },
        };
    },
    mounted() {
        this.$store.commit("START");
        this.showUpdate = localStorage.getItem("newVersion") ? true : false;
        localStorage.removeItem("newVersion");
    },
    created() {
        this.timer = setInterval(this.verify, 1000);
        window.addEventListener("beforeinstallprompt", (e) => {
            if (!this.$store.state.cancelInstall) {
                e.preventDefault();
                this.deferredPrompt = e;
            }
        });
        window.addEventListener("appinstalled", () => {
            this.deferredPrompt = null;
        });
    },
    methods: {
        changeDarkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            this.$store.commit("SET_DARK_MODE", this.$vuetify.theme.dark);
        },
        async dismiss() {
            this.deferredPrompt = null;
            this.$store.state.cancelInstall = true;
        },
        async install() {
            this.deferredPrompt.prompt();
        },
        verify() {
            if (this.$store.state.offline) {
                this.$http
                    .get("/")
                    .then(() => this.$store.commit("ONLINE"))
                    .catch(() => this.$store.commit("OFFLINE"));
            }
        },
        closeInfo() {
            this.info.show = false;
        },
    },
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
<style>
#create .v-speed-dial {
    position: absolute;
}

#create .v-btn--floating {
    position: relative;
}
</style>