<template>
    <div>
        <MenuLogged v-if="$store.getters.isLogged" />
        <MenuWithoutLogin v-else />
    </div>
</template>

<script>
import MenuLogged from "@/components/shared/MenuLogged";
import MenuWithoutLogin from "@/components/shared/MenuWithoutLogin";

export default {
    name: "Menu",
    components: {
        MenuLogged,
        MenuWithoutLogin,
    },
};
</script>